import styled from '@emotion/styled';
import { Container, Link, Typography } from '@mui/material';
import React from 'react';
import BroadformBuildingInsurancePolicyApril2023 from '../assets/documents/products-key-information/commercial/Broadform-Building-Insurance-Policy-April-2023.pdf';
import BroadformBuildingKeyInformationDocumentApril2023 from '../assets/documents/products-key-information/commercial/Broadform-Building-Key-Information-Document-April-2023.pdf';
import AverageWaiverExtension from '../assets/documents/products-key-information/community/Average-Waiver-Extension.pdf';
import CommunityLivingInsurancePolicyApril2023 from '../assets/documents/products-key-information/community/Community-Living-Insurance-Policy-April-2023.pdf';
import CommunityLivingKeyInformationDocumentApril2023 from '../assets/documents/products-key-information/community/Community-Living-Key-Information-Document-April-2023.pdf';
import EnhancedGeyserBenefit from '../assets/documents/products-key-information/community/Enhanced-Geyser-Benefit.pdf';
import DeclarationByManagingAgentFidelityAndComputerCrimePolicyNewBusinessAndRenewals from '../assets/documents/products-key-information/fidelity/CIA-Fidelity-Computer-Crime-Declaration-ManagingAgent.pdf';
import FidelityandComputerCrimeInsurancePolicy from '../assets/documents/products-key-information/fidelity/Fidelity_and_Computer_Crime_Insurance_Policy_1_March_2023.pdf';
import SummaryofChangestoFidelityComputerCrimePolicyOctober2022 from '../assets/documents/products-key-information/fidelity/Summary_of_Changes_to_Fidelity_Computer_Crime_Policy_October_2022.pdf';
import FidelityComputerCrimeQuotationProposalForm from '../assets/documents/products-key-information/fidelity/Fidelity-Computer-Crime-Quotation-Proposal-Form.pdf';
import BroadformHomeownersInsurancePolicyApril2023 from '../assets/documents/products-key-information/homeowners/Broadform-Homeowners-Insurance-Policy-April-2023.pdf';
import BroadformHomeownersKeyInformationDocumentApril2023 from '../assets/documents/products-key-information/homeowners/Broadform-Homeowners-Key-Information-Document-April-2023.pdf';
import CIAInsuranceProposal from '../assets/documents/products-key-information/CIA-Insurance-Proposal.pdf';
import BrokerApplicationForm from '../assets/documents/Broker-Application-Form.pdf';
import CIAClaimForm from '../assets/documents/CIA-Claim-Form.pdf';
import CIAFastTrackGeyserClaimForm from '../assets/documents/CIA-Fast-Track-Geyser-Claim-Form.pdf';
import CIAFidelityComputerCrimeClaimForm from '../assets/documents/CIA-Fidelity-Computer-Crime-Claim-Form.pdf';
import GeyserBrochure2023 from '../assets/documents/Geyser-Brochure-2023.pdf';
import CIAHotWorkPermit2023 from '../assets/documents/CIA-Hot-Works-Permit-2023.pdf';
import CIAAlternativeEnergy from '../assets/documents/CIA-ALTERNATIVE-ENERGY-2024.pdf';
import BroadformBuildingKeyInformation from '../assets/documents/Broadform Building Key Information Document 1 April 2023.pdf';
import CIAProposalForm from '../assets/documents/PROPOSAL-FORM.pdf';

const DocumentRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const LegalLink = styled(Link)(({ theme }) => ({
    marginBottom: '20px',
}));

const LinkContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const Downloads = () => {
    return (
        <DocumentRoot>
            <Container maxWidth="lg">
                <Typography variant="h3" marginBottom={3}>
                    Downloads
                </Typography>

                <LinkContainer>
                    <LegalLink href={AverageWaiverExtension} target="_blank">
                        Average Waiver Extension
                    </LegalLink>
                    <LegalLink href={CIAProposalForm} target="_blank">
                        CIA Proposal Form 10 June
                    </LegalLink>
                    <LegalLink href={BroadformBuildingInsurancePolicyApril2023} target="_blank">
                        Broadform Building Insurance Policy April 2023
                    </LegalLink>
                    <LegalLink
                        href={BroadformBuildingKeyInformationDocumentApril2023}
                        target="_blank"
                    >
                        Broadform Building Key Information Document April 2023
                    </LegalLink>
                    <LegalLink href={BroadformHomeownersInsurancePolicyApril2023} target="_blank">
                        Broadform Homeowners Insurance Policy April 2023
                    </LegalLink>
                    <LegalLink
                        href={BroadformHomeownersKeyInformationDocumentApril2023}
                        target="_blank"
                    >
                        Broadform Homeowners Key Information Document April 2023
                    </LegalLink>
                    <LegalLink href={BrokerApplicationForm} target="_blank">
                        Broker Application Form
                    </LegalLink>
                    <LegalLink href={CIAClaimForm} target="_blank">
                        CIA Claim Form
                    </LegalLink>
                    <LegalLink href={BroadformBuildingKeyInformation} target="_blank">
                        Broadform Building Key Information Document 1 April 2023
                    </LegalLink>
                    <LegalLink href={CIAAlternativeEnergy} target="_blank">
                        CIA ALTERNATIVE ENERGY
                    </LegalLink>
                    <LegalLink href={CIAFastTrackGeyserClaimForm} target="_blank">
                        CIA Fast Track Geyser Claim Form
                    </LegalLink>
                    <LegalLink href={CIAFidelityComputerCrimeClaimForm} target="_blank">
                        CIA Fidelity Claim Form
                    </LegalLink>
                    <LegalLink href={CIAHotWorkPermit2023} target="_blank">
                        CIA Hot Work Permit
                    </LegalLink>
                    <LegalLink href={CIAInsuranceProposal} target="_blank">
                        CIA Insurance Proposal
                    </LegalLink>
                    <LegalLink href={CommunityLivingInsurancePolicyApril2023} target="_blank">
                        Community Living Insurance Policy April 2023
                    </LegalLink>
                    <LegalLink
                        href={CommunityLivingKeyInformationDocumentApril2023}
                        target="_blank"
                    >
                        Community Living Key Information Document April 2023
                    </LegalLink>
                    <LegalLink
                        href={
                            DeclarationByManagingAgentFidelityAndComputerCrimePolicyNewBusinessAndRenewals
                        }
                        target="_blank"
                    >
                        Declaration By Managing Agent Fidelity And Computer Crime Policy New
                        Business And Renewals 2019
                    </LegalLink>
                    <LegalLink href={EnhancedGeyserBenefit} target="_blank">
                        Enhanced Geyser Benefit
                    </LegalLink>
                    <LegalLink href={FidelityandComputerCrimeInsurancePolicy} target="_blank">
                        Fidelity And Computer Crime Insurance Policy March 2023
                    </LegalLink>
                    <LegalLink href={FidelityComputerCrimeQuotationProposalForm} target="_blank">
                        Fidelity Computer Crime Quotation Proposal Form
                    </LegalLink>
                    <LegalLink href={GeyserBrochure2023} target="_blank">
                        Geyser Brochure 2023
                    </LegalLink>
                    <LegalLink
                        href={SummaryofChangestoFidelityComputerCrimePolicyOctober2022}
                        target="_blank"
                    >
                        Summary Of Changes To Fidelity Computer Crime Policy October 2022
                    </LegalLink>
                </LinkContainer>
            </Container>
        </DocumentRoot>
    );
};

export default Downloads;
