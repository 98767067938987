import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import AxiosRequest from '../../../../utils/axios/AxiosRequest';

const SearchContainerRoot = styled('div')(({ theme }) => ({
    padding: '24px 0',
    width: '100%',
}));

const SearchResults = styled('div')(({ theme }) => ({
    width: '410px',
    // width: '100%',
    position: 'absolute',
    backgroundColor: '#FFF',
    marginTop: '5px',
    maxHeight: '200px',
    overflowY: 'auto',
    border: '1px solid rgba(33, 33, 33, 0.07)',
    boxShadow: '0 3px 6px rgb(149 157 165 / 15%)',
    borderRadius: '0.6rem',
}));

const ResultItem = styled('div')(({ theme }) => ({
    padding: '8px 13px',
    cursor: 'pointer',
    color: '#212121',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderBottom: '',
    ':hover': {
        color: '#D7412E',
        // backgroundColor: '#DCE3F7',
    },
}));

const SearchContainer = (props) => {
    const { handlePostClick } = props;

    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async (event) => {
        const text = event.target.value;
        setSearchValue(text);

        if (text) {
            var result = await AxiosRequest.post(`api/newsletter/search`, { searchValue: text });
            setSearchResults(result);
            return false;
        }

        setSearchResults([]);
    };

    return (
        <SearchContainerRoot>
            <TextField
                variant="outlined"
                label="Search a Post"
                fullWidth
                size="small"
                value={searchValue}
                onChange={handleSearch}
            />
            {searchResults.length > 0 && (
                <SearchResults>
                    {searchResults.map((item, index) => (
                        <ResultItem
                            key={index}
                            onClick={() => {
                                handlePostClick(item);
                                setSearchValue(item['title']);
                                setSearchResults([]);
                            }}
                        >
                            {item['title']}
                        </ResultItem>
                    ))}
                </SearchResults>
            )}
        </SearchContainerRoot>
    );
};

export default SearchContainer;
