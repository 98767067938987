import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from '@mui/material/styles';

import Layout from './components/Layout';

import NotFound from './pages/NotFound';

// Application Theme Builder
import ThemeBuilderOptions from './helpers/app-theme/ThemeBuilderOptions';
import { links_array } from './constants/AppConstants';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/style.css';

const App = () => {
    return (
        <ThemeProvider theme={ThemeBuilderOptions}>
            <Layout>
                <Switch>
                    {links_array.map((nav_items) =>
                        nav_items['items'].map((link_items, index) => (
                            <Route
                                exact
                                path={link_items['link']}
                                component={link_items['component']}
                                key={index}
                            />
                        ))
                    )}

                    <Route exact path="*" component={NotFound} />
                </Switch>
            </Layout>
        </ThemeProvider>
    );
};

export default App;
